<template>
    <basic-page :pagedata="this.pageData" 
        class="doppio-layer" 
        addedclass="gray-bg" 
        :bannerimg="backgroundImage">

        <div v-if="this.pageData.attributes && this.pageData.attributes.field_body_01" 
            class="container-espe intro fullwidth gray-bg" 
            v-html="this.pageData.attributes.field_body_01.processed">
        </div>

        <div class="container-espe white-bg backstep scheda">

            <!-- 
                !! DON'T DELETE !!
                OLD CONTACT FORM TO REGISTER AS EXPERT ON THE PLATFORM
                MAY BE USED IN THE FUTURE
            -->
            <!--<form class="modulo-esperto" @submit.prevent="processForm">
                <h3>INSERISCI I TUOI DATI</h3>
                <div class="campo"><input required v-model="formData.Nome" type="text" name="Nome" placeholder="Nome"><span class="tick"></span></div>
                <div class="campo"><input required v-model="formData.Cognome" type="text" name="Cognome" placeholder="Cognome"><span class="tick"></span></div>
                <div class="campo"><input required v-model="formData.Email" type="email" name="Email" placeholder="Email"><span class="tick"></span></div>
                <div class="campo"><input v-model="formData.Cellulare" type="tel" name="Cellulare" placeholder="Cellulare"><span class="tick"></span></div>
                <br>
                <h6>MESSAGGIO</h6>
                <textarea required type="textarea" v-model="formData.Message" rows="8" placeholder="Massimo 100 parole"></textarea>
                <br>
                <div class="auth">
                    <input required type="checkbox" id="autorizzo" v-model="formData.Autorizzo">
                    <p><label for="autorizzo">Autorizzo il trattamento dei miei dati personali raccolti ai sensi del Dlgs 196 del 30 giugno 2003 e dell’art. 13 GDPR (Regolamento UE2016/679) per le finalità dell’iniziativa “EXPEROTARY”</label></p>
                </div>
                <br>
                <br>
                <br>
                <div class="button-container">
                    <button type="submit" class="btn">INVIA MODULO</button>
                </div>
            </form>-->

            <div class="button-container">
                <a href="https://my.rotary.org/it/search/club-finder" target="_blank" class="btn">TROVA IL ROTARY CLUB</a>
            </div>
            
            <br>
            <br>

        </div>
    </basic-page>
</template>

<script>
import BasicPage from './BasicPage.vue'
import { fetchSinglePage, fetchSingleNode } from '../libs/drupalClient'
export default {
    components: { BasicPage },
    name: 'diventa-un-esperto',
    data: () => {
        return {
            pageData: { 
                attributes: {
                    title: "",
                    body: {
                        summary: ""
                    }
                } 
            },
            formData: {
                Nome: "",
                Cognome: "",
                Email: "",
                Cellulare: "",
                Autorizzo: false,
                Message: ""
            },
            currentPageId: "5f5d1c23-4142-495c-8209-6b0d253d9be2",
            bannerData: {}
        }
    },
    methods: {  
        /*
        //TO ADD REGISTRATION URL AND DATA PROCESSING
        processForm(event) {
            event.preventDefault()

            
            var url = new URL(process.env.VUE_APP_POSTURL + "/post.json");
            Object.entries(this.formData).forEach(([k, v]) => url.searchParams.append(k, v));
            url.searchParams.append('ExpertId', 15)
            
            fetch(url, {
                method: "POST",
                body: this.formData,
                redirect: "follow"
            })
            .then(res => { 
                if(res.status==200) {
                    this.$store.commit('SET_FORM_DATA', {
                        type: "diventa-esperto",
                        name: this.formData.Nome,
                        surname: this.formData.Cognome,
                        email: this.formData.Email,
                        donation_amount: 0,
                        donation_project: "",
                        expert_contact: ""
                    })
                    this.$router.push('/confirmed')

                }
            })
            .catch(error => { console.log(error) })
        },
        */
    },
    computed: {
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        },
        backgroundImage() {
            if(this.bannerData.field_banner_top)
                return this.bannerData
            else 
                return {}
        }
    },
    mounted() {
        fetchSinglePage('page_type_02', this.currentPageId, { include: ['field_ref_banner_top'] }, this.lang)
        .then(res => {
            this.pageData = res[0]

            fetchSingleNode('banner', this.pageData.field_ref_banner_top.id, { include: ['field_banner_top'] }, this.lang)
            .then(res => {
                this.bannerData = res[0]
            })
        })
    }
}
</script>

<style lang="scss">
</style>